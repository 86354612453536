<template>
	<div id="Open">
		<div class="back_box">
			<div class="Title">
				<!-- <p>{{ DetailData.name }}</p> -->
			</div>
			
			<Details :DetailData="DetailData" :NumIdx="NumIdx" v-show="AnimationShow == '0'"></Details>
			<div class="Animation_switch" style="margin-bottom: 10px">
					<p>跳过动画</p>
					<van-switch v-model="Animation_checked" size="24px" />
				</div>
			<div class="voice_Switch" style="margin-bottom: 10px">
				<p>跳过声音</p>
				<van-switch v-model="voice_checked" size="24px" />
			</div>
			<!--  && (Detail.length==1 || Detail.length==3 || Detail.length==5) -->
			<template v-if="AnimationShow == '1'">
				<luckdrawX :ItemData="Awardlist" :CSnum="CSnum" :active='NumIdx' :List="DetailList" v-if="AnimationShow"
					@fromChild="fromChild">
				</luckdrawX>
				<!-- <luckdraw :ItemData="Awardlist" :CSnum="CSnum" :active='5' :List="DetailList" v-if="AnimationShow"
					@fromChild="fromChild">
				</luckdraw> -->
				<div class="Dynamic_out_one" style="z-index:9">
					<div style="color:#FFF;" v-for="(t, index) in Detail" :key="index + 'aa'" class="Dynamic_out">
						<div :style="LeftSty" :class="TranSitionSty" style="color:#FFF;display: flex;" class="">
							<div class="d-flex guotwos"  v-for="(item, index) in t.DetailList" :key="index + 'a'" :class="item.lv | LvGuoLv">
								<div class="cont_pic" style="width: 100px;background-size: 80% 100%;background-position: center;"
									:style="{ 'background-image': 'url(' + item.lv_bg_image + ')' }">
									<img style="height: 90px" :src="item.cover" alt="">
									<!-- <div>{{item.siyecao}}</div> -->
								</div>
								<div class="item-bottom" :class="`bg-${item.lv} show-${item.lv}`"></div>
							</div>
						</div>
					</div>
				</div>
			</template>

			<!-- <template v-if="AnimationShow == '1' && (Detail.length==2 || Detail.length==4)">
				<luckdraw :ItemData="Awardlist" :CSnum="CSnum" :active='NumIdx' :List="DetailList" v-if="AnimationShow"
					@fromChild="fromChild">
				</luckdraw>
				<div class="d-flex Dynamic_out_top" style="display: flex; z-index:9;" >
					<div style="width:120px;color:#FFF;height: 200px;overflow: hidden;-webkit-mask-image:none"
						v-for="(t, index) in Detail" :key="index + 'aa'" class="Dynamic_out">
						<div :style="topSty" :class="TranSitionSty" style="color:#FFF;" class="">
							<div class="guotwos" v-for="(item, index) in t.DetailList" :key="index + 'a'" :class="item.lv | LvGuoLv" style="z-index:10">
								<div class="cont_pic" :class="item.lv | LvGuoLv" style="width: 100%;height:50px;background-size: 80% 100%;background-position: center;"
									 >
									<img style="height: 90%;" :src="item.cover" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</template> -->
			<!-- <div class="bottom_box" ref="botbox">
				<img src="../../assets/images/open/bottomOne1.png" alt="">
			</div> -->
			<!-- 开箱 -->
			<!-- <Dynamic  :DetailList="DetailList" :Awardlist="Awardlist" 
			:Dynamic_checked="Dynamic_checked" 
			:Dynamic_num="Dynamic_num" 
			@setAnimation="setAnimation" @setEquipment="setEquipment" 
			v-show="AnimationShow == '1'"></Dynamic> -->

			<div class="numbean" v-show="AnimationShow == '0'">
				<div class="box_num" v-show="AnimationShow == '0'">
					<p v-for="(i, index) in 5" :class="{ 'active': NumIdx == index + 1 }" @click="NumIdAdd(index)">{{ i }}</p>
				</div>
				<div v-show="AnimationShow == '0'" style="font-size:18px">
					<img src="@/assets/images/public/Gold.png" alt="" style="width: 18px;">{{(DetailData.bean * NumIdx).toFixed(2)}}
				</div>
			</div>
			<div class="open_btn" v-show="AnimationShow == '0'">
				<img src="../../assets/images/985/open/openbacs.png" @click="PostOpen" alt="" style="width:100px">
				<!-- <div  @click="xiang=true">
					<img src="../../assets/images/985/open/gongbox.png" alt="" style="width:50px">
					<p>查看箱内饰品</p>
				</div> -->
				<!-- <van-button type="info" size="small" @click="PostOpen" style="color:#000 !important">开启箱子</van-button> -->
			</div>
		</div>
		<Equipment :Dynamic_num="Dynamic_num" :EquipmentItem="EquipmentItem" v-show="EquipmentShow == '1'"
			:key="EquipmentShow"></Equipment>
		<Goods :DetailList="DetailList" :id="DetailData.id" :key="timer"></Goods>
		<Show v-show="WinningShow" :WinningShow="WinningShow" @setShow="setShow" :Awardlist="Awardlist"></Show>
		<div class="xiangnei" v-show="xiang==true">
			<div class="littlexiang">
				<h2>箱内物品</h2>
					<span  class="closes"
			@click="xiang = false">×</span>
				<div class="Explosive">
					<p><img src="../../assets/images/open/yellow.png" alt="">:{{yellow.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/red.png" alt="">:{{red.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/prople.png" alt="">:{{purple.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/blue.png" alt="">:{{blue.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/hui.png" alt="">:{{gray.toFixed(2)}}%</p>
				</div>
				<div class="Goods_list" >
					<div class="Goods_item" v-for="(item,index) in DetailList" :key="index" :class="item.lv | LvGuoLv" @click="goodItem(item)"  v-show="item.lv==1&& DetailList[0].lv==6 ?flags:true">
						<div class="probability">
							<span :style="item.name=='金球'?'display:none':''"><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
							<!-- <span :style="item.name=='金球'?'display:none':''">
								概率：{{item.odds_percent}}
							</span> -->
						</div>
						<div class="Goods_pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
							<img :src="item.cover" alt="">
						</div>
						<p class="text-overflow" style="width:80%">{{item.name}}</p>

						<!-- <div class="" style="font-size: 12px;color: #FFF;margin-top:10px;margin-bottom:10px;">{{item.dura_alias}}</div> -->

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Details from '@/components/PubOpen/Details.vue'
import Dynamic from '@/components/PubOpen/Dynamic.vue'
import Equipment from '@/components/PubOpen/Equipment.vue'
import Goods from '@/components/PubOpen/Goods.vue'
import Show from '@/components/PubOpen/Show.vue'
import { BoxDetail, Open, PersonalInfo } from '@/network/api.js'
import luckdrawX from './component/luckdrawX.vue'
import luckdraw from './component/luckdraw.vue'
import { mapMutations } from 'vuex'
export default {
	name: 'Open',
	data() {
		return {
			LeftSize: '0', //偏移距离
			topSize: '0', //偏移距离
			TranSition: 'box-an-active', //偏移距离
			// 开箱子横排使用
			Detail: [],
			DetailData: {},//箱子信息
			NumIdx: 0,//开箱数量
			DetailList: [],//箱内物品
			timer:null,
			Animation_checked: false, //动画开关
			voice_checked:false, //声音开关
			WinningShow: false,//奖品遮罩层
			Awardlist: [],//获得奖品数据
			Dynamic_checked: '0',//播放动画('0':不播放,'1':播放)
			Dynamic_num: 0,//奖品数量
			AnimationShow: '0',//动画显示隐藏'0':不显示,'1':显示)
			EquipmentItem: {},//下方奖品栏子级
			EquipmentShow: '0',//下方奖品栏显示
			process: new Audio(require('@/assets/audio/process.mp3')), //开奖过程声音
			yellow:0,
			red:0,
			purple:0,
			blue:0,
			gray:0,
			xiang:false,
			CSnum: 0,
		}
	},

	watch: {
		DetailList(val){
			// console.log(this.DetailList);
			if(this.DetailList.length>0){
				this.yellow=0,
				this.red=0,
				this.purple=0,
				this.blue=0,
				this.gray=0
				setTimeout(() => {
					this.DetailList.forEach((item,index)=>{
						if(item.lv==1){
							this.yellow+=Number(item.odds)
						}
						if(item.lv==2){
							this.red+=Number(item.odds)
						}
						if(item.lv==3){
							this.purple+=Number(item.odds)
						}
						if(item.lv==4){
							this.blue+=Number(item.odds)
						}
						if(item.lv==5){
							this.gray+=Number(item.odds)
						}
					})
				}, 200);
			}
		}
	},
	created() {
		this.GetBoxDetail()
	
		setTimeout(() => {
			this.NumIdAdd(0)
		}, 500)
		if(this.DetailList.length>0){
			this.yellow=0,
			this.red=0,
			this.purple=0,
			this.blue=0,
			this.gray=0
			setTimeout(() => {
				this.DetailList.forEach((item,index)=>{
					if(item.lv==1){
						this.yellow+=Number(item.odds)
					}
					if(item.lv==2){
						this.red+=Number(item.odds)
					}
					if(item.lv==3){
						this.purple+=Number(item.odds)
					}
					if(item.lv==4){
						this.blue+=Number(item.odds)
					}
					if(item.lv==5){
						this.gray+=Number(item.odds)
					}
				})
			}, 500);
		}
	},
	updated(){
		if(this.AnimationShow == '1'){
			// this.$refs.botbox.style.marginTop='50px';
			setTimeout(() => {
				// this.$refs.botbox.style.marginTop='0';
			}, 8000);
		}
	},
	computed: {
		//偏移样式
		LeftSty() {
			return 'transform: translateX(' + (this.LeftSize) + 'px)'
		},
		topSty() {
			return 'transform: translateY(' + (this.topSize) + 'px)'
		},
		//过渡样式
		TranSitionSty() {
			return this.TranSition
		}
	},
	filters: {
		LvGuoLv(val){
			if (val == '1') {
				return 'lv1'
			} else if (val == '2') {
				return 'lv2'
			} else if (val == '3') {
				return 'lv3'
			} else if (val == '4') {
				return 'lv4'
			}else if (val == '5') {
				return 'lv5'
			}else if (val == '6') {
				return 'lv6'
			}
		},
		GuoLv(val) {
			if (val == '1') {
				return 'color:#e6ba1c'
			} else if (val == '2') {
				return 'color:#d32ce6'
			} else if (val == '3') {
				return 'color:#834fff'
			} else if (val == '4') {
				return 'color:#4b69ff'
			} else if (val == '5') {
				return 'color:#bbbbbb'
			}else if (val == '6'){
				return 'color:#e6ba1c'
			}
		},
		GuoLvTwo(val){
			if (val == '1') {
				return require('@/assets/images/q2/openbox/1.png')
			} else if (val == '2') {
				return require('@/assets/images/q2/openbox/2.png')
			} else if (val == '3') {
				return require('@/assets/images/q2/openbox/3.png')
			} else if (val == '4') {
				return require('@/assets/images/q2/openbox/4.png')
			} else if (val == '5') {
				return require('@/assets/images/q2/openbox/5.png')
			} else if (val == '6') {
				return require('@/assets/images/Swiper/Lv1.png')
			}
		}
	},
	methods: {
		//宝箱详情
		GetBoxDetail() {
			BoxDetail(this.$route.query.id).then((res) => {
				console.log(res.data.data.box_awards)
				this.DetailData = res.data.data
				this.DetailList = res.data.data.box_awards
				// console.log(this.DetailList)
				this.NumIdAdd(0)
			})
		},
		NumIdAdd(index) {
			this.Detail = []
			let arr = []
			for (let i = 0; i < 50; i++) {
				arr.push(this.DetailList[parseInt(Math.random() * this.DetailList.length)])
			}

			for (let i = 0; i < index + 1; i++) {
				this.Detail.push({ DetailList: this.randSort(arr) })
			}
			arr = []
			this.NumIdx = index + 1
			console.log(this.Detail);
		},
		// 打乱数组
		randSort(A_Arr) {
			let arr = []
			for (let i = 0, len = A_Arr.length; i < len; i++) {
				arr.push(A_Arr[parseInt(Math.random() * A_Arr.length)])
			}
			return arr
		},
		

		//开箱
		 PostOpen() {
			Open(this.$route.query.id, this.NumIdx).then((res) => {
				this.GetPersonalInfo()
				this.timer = new Date().getTime();
				// console.log(this.Detail);

				this.Detail = this.Detail.map((item, index) => {
					res.data.data.awardlist[index].siyecao = '中将'
					item.DetailList[30] = res.data.data.awardlist[index]
					return item
				})
				this.Awardlist = res.data.data.awardlist
				if (this.Animation_checked == true) {
					this.WinningShow = true
				} else {
					if(this.voice_checked==false) {
						this.process.play()//播放声音
						this.AnimationShow = '1'
						this.Dynamic_checked = '1'
						setTimeout(() => {
							this.LeftSize = -(100 * 31 - 250) // 确定中将
							this.topSize = -(50 * 30 - 75) // 确定中将
						}, 100)
						setTimeout(() => {
							this.WinningShow = true
							this.LeftSize = 0
							this.topSize = 0
							this.AnimationShow = '0'
						}, 8000)
					}else{
						this.AnimationShow = '1'
						this.Dynamic_checked = '1'
						setTimeout(() => {
							this.LeftSize = -(100 * 31 - 250) // 确定中将
							this.topSize = -(50 * 30 - 75) // 确定中将
						}, 100)
						setTimeout(() => {
							this.WinningShow = true
							this.LeftSize = 0
							this.topSize = 0
							this.AnimationShow = '0'
						}, 8000)
					}
					
				}
			})
		},
		PostOpen() {
			if (this.Animation_checked == true) {
				this.disabled = true
				Open(this.$route.query.id, this.NumIdx).then((res) => {
					this.Awardlist = res.data.data.awardlist
					this.GetPersonalInfo()
					// this.ZhongJiang()
					// this.Select_All()
					this.WinningShow = true
				})
			} else {
				if(localStorage.getItem('token')){
					//正常工作
					this.AnimationShow = '1'
					// this.Animation_checked = true
					Open(this.$route.query.id, this.NumIdx).then((res) => {
						this.Awardlist = res.data.data.awardlist
						this.CSnum += 1
						this.GetPersonalInfo()
						// this.Select_All()
					})
					// if(this.Detail.length==2 || this.Detail.length==4){
					// 	setTimeout(() => {
					// 		this.AnimationShow = '0'
					// 		this.WinningShow=true
					// 	}, 9500)
					// }else{
					
					// }
					setTimeout(() => {
						this.AnimationShow = '0'
						this.WinningShow=true
					}, 6500)
				}else{
					this.$message.error('请登录后开启箱子');
				}
				
			}
		},
		fromChild(v) {
			// console.log(v)
			this.disabled = false
			this.luckdrawShow = false
		},
		//个人信息
		GetPersonalInfo() {
			PersonalInfo().then((res) => {
				this.PostUser(res.data.message)
			})
		},

		//接收子级(关闭奖品遮罩层)
		setShow(v) {
			this.AnimationShow = '0'
			this.Dynamic_checked = '0'
			this.WinningShow = v
			this.EquipmentShow = '0'
		},

		//接收子级(动画结束)
		setAnimation(v) {
			this.WinningShow = v
			this.AnimationShow = '0'
			this.Dynamic_checked = '0'
		},

		//接收子级(下方奖品栏)
		setEquipment(v) {
			// console.log(v)
			this.EquipmentItem = this.Awardlist[v - 1]
		},

		...mapMutations([
			'PostUser'
		])
	},
	destroyed() {
		// 销毁监听
		this.process.pause()
	},
	components: {
		Details,
		Dynamic,
		Equipment,
		Goods,
		Show,
		luckdrawX,
		luckdraw
	}
}
</script>

<style scoped lang="scss">
.guotwos{
	background-size: 100% 100% !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	&.lv1{
		background-size: 100% 100%;
		border-radius: 5px;
		// border: 1px solid rgba(233, 212, 90, 0.358);
		background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
		background: url('../../assets/images/985/open/1.png') no-repeat center;
		background-size: 100% 100%;
	}
	&.lv2{
		background-size: 100% 100%;
		border-radius: 5px;
		// border: 1px solid rgb(210, 73, 73);
		background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(133, 46, 46));
		background: url('../../assets/images/985/open/4.png') no-repeat center;
		background-size: 100% 100%;
	}
	&.lv3{
		background-size: 100% 100%;
		border-radius: 5px;
		// border: 1px solid rgb(203, 70, 203);
		background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,44,83));
		background: url('../../assets/images/985/open/3.png') no-repeat center;
		background-size: 100% 100%;
	}
	&.lv4{
		background-size: 100% 100%;
		border-radius: 5px;
		// border: 1px solid rgb(86, 86, 214);
		background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,79,102));
		background: url('../../assets/images/985/open/2.png') no-repeat center;
		background-size: 100% 100%;
	}
	&.lv5{
		background-size: 100% 100%;
		border-radius: 5px;
		// border: 1px solid rgb(96, 96, 113);
		background: linear-gradient(to top, rgba(81, 72, 72, 0), rgb(87, 92, 104));
		background: url('../../assets/images/985/open/5.png') no-repeat center;
		background-size: 100% 100%;
	}
	&.lv6{
		background-size: 100% 100%;
		border-radius: 5px;
		// border: 1px solid rgba(233, 212, 90, 0.358);
		background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
		background: url('../../assets/images/985/open/1.png') no-repeat center;
		background-size: 100% 100%;
	}
}
.xiangnei{
	position: fixed;
	background: rgba($color: #000000, $alpha: 1);
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.littlexiang{
		width: 90%;
		height: 500px;
		margin: 100px auto;
		background: url('../../assets/images/q2/openbox/xiangbac.png') no-repeat center;
		background-size: 100% 100%;
		padding: 10px;
		.closes{
			position: absolute;
			width: 30px;
			height: 30px;
			right: 5px;
			top: 100px;
			font-size: 30px;
			color: white
		}
		h2{
			color: white;
			text-align: center;
			position: relative;
			top: 0;
		}
	}
}
.voice_Switch{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 10px;
	margin-top: -20px;
	box-sizing: border-box;
	font-size: 16px;
	color: #fff;
	position: absolute;
	top: 100px;
	right: 0;
	background: rgba($color: #000000, $alpha: 0.3);

	p {
		margin-right: 5px;
	}
}
.Animation_switch {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 10px;
	margin-top: 10px;
	box-sizing: border-box;
	font-size: 16px;
	color: #fff;
	position: absolute;
	top: 100px;
	right: 0;
	background: rgba($color: #000000, $alpha: 0.3);

	p {
		margin-right: 5px;
	}
}
#Open {
	.item-bottom{
		width: 100%;
		height: 5%;
		bottom: 0;
		position: relative;
		bottom: 0;
		// background: red;
	}
	.back_box{
		background: url('../../assets/images/985/open/boxbac.png') no-repeat center;
		background-size: 200% 80%;
		background-position: center -20px;
		height: 500px;
	}
	.contItem {
		// background: url(../../assets/images/public/pub_back.png) no-repeat;
		background-position: center;
		background-size: 95%;
	}
	.Details_Price{
		z-index: 9;
		margin-bottom: -1.5rem;
		margin-top: 1rem;
		img{
			width: 15px !important;
			height: 15px !important;
		}
	}
	.cont_pic {
		// padding:0 10px;
		font-size: 12px;
		line-height: .85;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;

		& img {
			margin: 0 auto;
		}
	}
	.bottom_box{
		text-align: center;
		img{
			width: 100%;
			position: relative;
			margin: 0 auto;
			margin-top: -5.1rem;
			
		}
	}
	.Dynamic_out_one {
		position: relative;
		top: 220px;
		// height: 100px;
		background: url('../../assets/images/q2/home/swiperbac.png') no-repeat center !important;
		background-size: 100% 100% !important;
		padding: 10px;
		padding-top: 50px !important;
		padding-bottom: 25px !important;
		overflow:  hidden !important;
		&::before {
			content: "";

			width: 2px;
			height: 100%;
			top: 35px;
			background-color: #5188ab;
			box-shadow: 0 0 13px 3px #5188ab;
			position: absolute;
			left: 50%;
			z-index: 999;
		}
	}

	.Dynamic_out {
		padding: 5px 0;
		overflow: hidden;
		// mask-image: linear-gradient(90deg, rgba(25, 25, 25, 0) 4%, #000 50%, rgba(25, 25, 25, 0) 96%);
		// -webkit-mask-image: linear-gradient(90deg, rgba(25, 25, 25, 0) 4%, #000 50%, rgba(25, 25, 25, 0) 96%);
		position: relative;

	}

	.Dynamic_out_top {
		// -webkit-mask-image: linear-gradient(0, rgba(25, 25, 25, 0) 4%, #000 50%, rgba(25, 25, 25, 0) 96%);
		position: relative;
		top: 200px;
		background: url('../../assets/images/q2/home/swiperbac.png') no-repeat center !important;
		background-size: 100% 100% !important;
		padding: 10px;
		padding-top: 50px !important;
		padding-bottom: 25px !important;
		overflow:  hidden !important;
		.Dynamic_out {
			&::before {
				display: none !important;
			}
		}

		&::after {
			width: 100%;
			height: 2px;
			content: "";
			background-color: #5188ab;
			box-shadow: 0 0 13px 3px #5188ab;
			position: absolute;
			top: 50%;
			z-index: 999;
		}
	}

	overflow-x: hidden;

	.box-an-active {
		transition: all 6s cubic-bezier(0, 0, 0.28, 1);
	}
	.numbean{
		// background: black;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		div:nth-child(2){
			// line-height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.box_num {
		width: 250px;
		padding: 15px 0;
		// margin: -2.2rem auto 0;
		display: flex;
		justify-content: center;
		/*border-radius: 6px;*/
		
		p {
			width: 50px;
			height: 30px;
			background: url('../../assets/images/q2/openbox/num.png') no-repeat;
			background-size: 100% 100%;
			color: #fff;
			text-align: center;
			line-height: 30px;
			font-weight: 900;
			font-style: italic;
			text-align: center;
			/*border-radius: 4px;*/
			margin: 0 5px;
			z-index: 9;
			&.active {
				background: url('../../assets/images/q2/openbox/numactive.png') no-repeat;
				background-size: 100% 100%;
				color: #db8641;
			}
		}
	}

	.open_btn {
		text-align: center;
		position: relative;
		div{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			color: white;
			position: absolute;
			right: 10px;
			bottom: 20px;
			p{
				width: 100%;
			}
		}
		.van-button {
			font-size: 14px;
			padding-left: 20px;
			padding-right: 20px;
			border-radius: none !important;
			color: #fff !important;
			background-image: url("../../assets/images/btn.jpg") !important;
			background-size: 100% 100% !important;
		}

		
	}
}
.Goods_list{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	border-radius: 0 4px 4px 4px;
	justify-content: space-between;
	overflow: scroll;
	height: 440px;
	align-content: flex-start;
	&::after{
		display: block;
		content: '';
		width: 32.5%;
	}
	.Goods_item{
		&.lv1{
			background-size: 100% 100%;
			border-radius: 5px;
			border: 1px solid rgba(233, 212, 90, 0.358);
			background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
			background: url('../../assets/images/985/open/1.png') no-repeat center;
			background-size: 100% 100%;
		}
		&.lv2{
			background-size: 100% 100%;
			border-radius: 5px;
			// border: 1px solid rgb(210, 73, 73);
			background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(133, 46, 46));
			background: url('../../assets/images/985/open/4.png') no-repeat center;
			background-size: 100% 100%;
		}
		&.lv3{
			background-size: 100% 100%;
			border-radius: 5px;
			border: 1px solid rgb(203, 70, 203);
			background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,44,83));
			background: url('../../assets/images/985/open/3.png') no-repeat center;
			background-size: 100% 100%;
		}
		&.lv4{
			background-size: 100% 100%;
			border-radius: 5px;
			border: 1px solid rgb(86, 86, 214);
			background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,79,102));
			background: url('../../assets/images/985/open/2.png') no-repeat center;
			background-size: 100% 100%;
		}
		&.lv5{
			background-size: 100% 100%;
			border-radius: 5px;
			border: 1px solid rgb(96, 96, 113);
			background: linear-gradient(to top, rgba(81, 72, 72, 0), rgb(87, 92, 104));
			background: url('../../assets/images/985/open/5.png') no-repeat center;
			background-size: 100% 100%;
		}
		&.lv6{
			background-size: 100% 100%;
			border-radius: 5px;
			border: 1px solid rgba(233, 212, 90, 0.358);
			background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
			background: url('../../assets/images/985/open/1.png') no-repeat center;
			background-size: 100% 100%;
		}
		width: 46%;
		// background: rgba($color: #000000, $alpha: .2);
		background-size: 100% 100% !important;
		// background-size: cover;
		height: 130px;
		border-radius: 4px;
		text-align: center;
		margin: 5px;
		background-position: -20px -20px;
		padding-top: 10px;
		.probability{
			justify-content: space-between;
			display: flex;
			width: 100%;
			height: 30px;
			line-height: 25px;
			font-size: 10px;
			color: #fff;
			text-align: left;
			padding: 0 20px;
			box-sizing: border-box;
			& span:nth-child(1){
				color:#ECB105 ;
			}
			& span:nth-child(2){
				color:#dbdce8 ;
			}
		}
		.Goods_pic{
			width: 80%;
			height: 70px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			background-image: url(http://csgo.api.gameskins.cn/uploads/images/baeef9d5696b72118858f87ff955bee7.png);
			background-position: center;
			background-size: 80%;
			background-repeat: no-repeat;
			img{
				max-width: 70%;
				vertical-align: middle;
			}
		}
		p{
			font-size: 12px;
			color: #fff;
			width: 80%;
			text-align: center;
			margin: 0 auto;
			overflow: hidden;
			/* 第二步：让文本不会换行， 在同一行继续 */
			white-space: nowrap;
			/* 第三步：用省略号来代表未显示完的文本 */
			text-overflow: ellipsis;
		}
		span{
			display: flex;
			justify-content: center;
			align-items: center;
			color: #f1bc60;
			margin: 5px 0;
			img{
				width: 16px;
			}
		}
		
	}
}
.Explosive{
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background: url(../../assets/images/open/Explosive.png) no-repeat center;
	background-size: 100% 100%;
	height: 30px;
	color: white;
	width: 98%;
	margin: 5px auto;
	img{
		width: 10px;
	}
}
// .Goods_box{
// 	margin-top: 50px !important;
// }
</style>
